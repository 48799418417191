import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { renderToStaticMarkup } from 'react-dom/server'
import QRCode from 'react-qr-code'
import React from 'react'

const generateQRCodeSVG = value => {
  const qrCodeElement = (
    <QRCode
      size={128}
      style={{ height: '75px', width: '75px' }}
      value={value}
      // viewBox={`0 0 128 128`}
    />
  )
  return renderToStaticMarkup(qrCodeElement)
}

export const ELetterServiceAR = ({ docType, previewDetail, paramVal }) => {
  const debtorDetails = {
    '[DebtorName]':
      previewDetail?.DebtorAccount?.CompanyName! ??
      previewDetail?.DebtorAccount?.DebtorName!,
    '[DebtorAddress]': `${previewDetail?.DebtorAccount?.Address?.address}<br />
          ${
            previewDetail?.DebtorAccount?.Address?.country === 'Singapore'
              ? `${previewDetail?.DebtorAccount?.Address?.country} ${previewDetail?.DebtorAccount?.Address?.postCode}`
              : `${previewDetail?.DebtorAccount?.Address?.postCode} ${previewDetail?.DebtorAccount?.Address?.city}, 
            ${previewDetail?.DebtorAccount?.Address?.state}, ${previewDetail?.DebtorAccount?.Address?.country}`
          }
           <br />`,
    '[DebtorContactName]': previewDetail?.DebtorAccount?.DebtorName! ?? '',
    '[DebtorContactNo]': previewDetail?.DebtorAccount?.OfficeNo! ?? '',
    '[DebtorContactEmail]':
      previewDetail?.DebtorAccount?.DebtorContact?.Email! ?? '',
    '[DebtorCompanyRegNo]': previewDetail?.DebtorAccount?.CompanyRegNo! ?? '',
    '[DebtorBankAccName]': previewDetail?.DebtorAccount?.BankName! ?? '',
    '[DebtorBankAccNo]': previewDetail?.DebtorAccount?.BankAccountNo! ?? '',
    '[DebtorTIN]': previewDetail?.DebtorAccount?.einvoice_TIN! ?? '',
    '[TaxSSTRegNo]': previewDetail?.SSTRegNo! ?? '',
    '[TaxGSTRegNo]': previewDetail?.GSTRegNo! ?? '',
    '[DocumentNo]': previewDetail?.DocNo! ?? '',
    '[DocumentDate]': dateFormat(previewDetail?.DocDate!) ?? '',
    '[ReferenceNo]': previewDetail?.RefNo! ?? '',
    '[SumTotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
    '[TotalAmt]': amtStr(previewDetail?.DocAmt) ?? '',
    '[QRCode]':
      previewDetail?.is_einvoice && previewDetail?.einvoice_qr_url
        ? generateQRCodeSVG(previewDetail?.einvoice_qr_url)
        : '',
  }

  switch (docType) {
    case 'official-receipt':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[BankCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[BankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
        '[PaymentMethod]': previewDetail?.PaymentMethod?.Name! ?? '',
        '[ChequeNo]': previewDetail?.ChequeNo! ?? '',
      })
      break
    case 'refund':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[BankCode]': previewDetail?.BankAccount?.Code! ?? '',
        '[BankAccNo]': previewDetail?.BankAccount?.AccountNo! ?? '',
      })
      break
    case 'invoice':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark!,
        '[Description]': previewDetail?.Description! ?? '',
        '[TaxRate]': amtStr(previewDetail?.ARInvoiceItem[0]?.TaxRate) ?? '',
        '[TaxAmt]': amtStr(previewDetail?.ARInvoiceItem[0]?.TaxAmt) ?? '',
        '[BaseAmt]': amtStr(previewDetail?.ARInvoiceItem[0]?.Amount) ?? '',
        '[DueDate]': dateFormat(previewDetail?.DueDate!) ?? '',
        '[TaxGSTRegNo]': previewDetail?.DebtorAccount?.GSTRegNo! ?? '',
      })
      break
    case 'credit-note':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[TaxRate]': amtStr(previewDetail?.ARCreditNoteItem[0]?.TaxRate) ?? '',
        '[TaxAmt]': amtStr(previewDetail?.ARCreditNoteItem[0]?.TaxAmt) ?? '',
        '[BaseAmt]': amtStr(previewDetail?.ARCreditNoteItem[0]?.Amount) ?? '',
        '[SumBaseAmt]':
          amtStr(
            previewDetail?.ARCreditNoteItem?.reduce(
              (a, b) => a + Number(b?.Amount),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTaxAmt]':
          amtStr(
            previewDetail?.ARCreditNoteItem?.reduce(
              (a, b) => a + Number(b?.TaxAmt),
              0
            ).toFixed(2)
          ) ?? '',
      })
      break
    case 'debit-note':
      Object.assign(paramVal, debtorDetails, {
        '[Remark]': previewDetail?.Remark! ?? '',
        '[Description]': previewDetail?.Description! ?? '',
        '[DueDate]': dateFormat(previewDetail?.DueDate!) ?? '',
        '[TaxRate]': amtStr(previewDetail?.ARDebitNoteItem[0]?.TaxRate) ?? '',
        '[TaxAmt]': amtStr(previewDetail?.ARDebitNoteItem[0]?.TaxAmt) ?? '',
        '[BaseAmt]': amtStr(previewDetail?.ARDebitNoteItem[0]?.Amount) ?? '',
        '[SumBaseAmt]':
          amtStr(
            previewDetail?.ARDebitNoteItem?.reduce(
              (a, b) => a + Number(b?.Amount),
              0
            ).toFixed(2)
          ) ?? '',
        '[SumTaxAmt]':
          amtStr(
            previewDetail?.ARDebitNoteItem?.reduce(
              (a, b) => a + Number(b?.TaxAmt),
              0
            ).toFixed(2)
          ) ?? '',
      })
      break
  }
}
